  body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    overflow: hidden;
  }

  .App {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    overflow: hidden;
  }

  .back {
    width: 102vw;
    height: 104vh;
    max-width: 2000px;
    max-height: 1000px;
    background-image: url('./img/background.png');
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    opacity: 0.45;
    animation: move 4s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }

  @keyframes move {
    0% {
      rotate: -1deg;
      background-size: 101.5%;
    }

    25% {
      rotate: 0deg;
      background-size: 101%;
    }

    50% {
      rotate: 1deg;
      background-size: 99.5%;
    }

    75% {
      rotate: 0deg;
      background-size: 101%;
    }

    100% {
      rotate: -1deg;
      background-size: 101.5%;
    }
  }


  .crt::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(18, 16, 16, 0.1);
    opacity: 0;
    z-index: 2;
    pointer-events: none;
    animation: flicker 0.15s infinite;
  }

  .crt::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%),
      linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    z-index: 2;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
    transform: rotateX(2deg) rotateY(-2deg);
    /* Garante que o filtro CRT acompanhe a curvatura */
    transform-origin: center;
  }

  .crt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 102vw;
    height: 104vh;
    max-width: 2000px;
    max-height: 1000px;
    animation: textShadow 10s infinite;
    flex-direction: column;
    justify-content: center;
  }

  @keyframes flicker {
    0% {
      opacity: 0.27861;
    }

    5% {
      opacity: 0.34769;
    }

    10% {
      opacity: 0.23604;
    }

    15% {
      opacity: 0.90626;
    }

    20% {
      opacity: 0.18128;
    }

    25% {
      opacity: 0.83891;
    }

    30% {
      opacity: 0.65583;
    }

    35% {
      opacity: 0.67807;
    }

    40% {
      opacity: 0.26559;
    }

    45% {
      opacity: 0.84693;
    }

    50% {
      opacity: 0.96019;
    }

    55% {
      opacity: 0.08594;
    }

    60% {
      opacity: 0.20313;
    }

    65% {
      opacity: 0.71988;
    }

    70% {
      opacity: 0.53455;
    }

    75% {
      opacity: 0.37288;
    }

    80% {
      opacity: 0.71428;
    }

    85% {
      opacity: 0.70419;
    }

    90% {
      opacity: 0.7003;
    }

    95% {
      opacity: 0.36108;
    }

    100% {
      opacity: 0.24387;
    }
  }



  .header {
    width: 70%;
    /* Ajuste proporcional à posição dos pilares */
    height: 40vw;
    /* Proporcional à largura da tela para acompanhar a diminuição */
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .logo {
    width: 15%;
    left: 15%;
    top: 5%;
    border-radius: 100%;
    position: absolute;
  }

  .nuvem {
    width: 50%;
    left: 32%;
    top: 8%;
    position: absolute;
  }





  .pilar {
    position: absolute;
    width: 20.5%;
    z-index: 10;
    top: 49.5%;
    transform: translateY(-50%);
  }

  .e {
    left: 5%;
  }

  .d {
    right: 5%;
    transform: translateY(-50%) scaleX(-1);
  }

  .section{
    width: 64%;
    height: 60%;
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 35%;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .prompt{
    width: 20%;
    height: 20%;
    background-color: aliceblue;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9vw;
    font-weight:bold;
    cursor: pointer;
    margin-left: 5%;
    margin-right: 5%;
    transition: transform 0.3s ease; /* Adiciona uma transição suave */
    text-align: center;
  }

  .prompt:hover{
    transform: scale(1.1);
  }

  .output {
    width: 28vw;
    height: 5vh;
    border-radius: 2px;
    font-size: calc(1.4vw - 0.8vh);
    font-weight: bold;
    z-index: 1000;
    display: flex;
    align-items: center;
    top: 4.7vw; /* Movimenta verticalmente de acordo com a altura da viewport e largura */
    left: 55%;
    transform: translateX(-50%);
    position: absolute;
  }


  .update-button{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10000;
    width: 6vh;
    font-size: 0.9vw;
    height: 1.2vw;
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease; /* Adiciona uma transição suave */
    text-align: center;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 4%;
  }

  .update-button:hover{
    background-color:beige;
  }

  .question{
    padding-left: 2%;
    padding-right: 2%;
  }

  .Social{
    width: 17.5vw;
    height: 2vw;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 1000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: -1vw;
  }

  .sociais{
    font-size: 0.9vw;
    height: 1.2vw;
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease; /* Adiciona uma transição suave */
    text-align: center;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 4%;
  }

  .sociais:hover{
    transform: scale(1.1);
  }

  a{
    text-decoration: none;
    color: black;
  }

  .ca{
    text-decoration: underline;
    position: absolute;
    background-color: aliceblue;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 0.2%;
    padding-bottom: 0.2%;
    border-radius: 2px;
    left: 50%;
    transform: translateX(-50%);
    top: 1.3vw;
    font-size: 0.9vw;
    cursor: pointer;
  }